import { Configuration } from './contexts/ConfigProvider/ConfigProvider';

const config: Configuration = {
  backendUrl: 'https://be.kendrickheller.com/',
  backendWs: 'wss://be.kendrickheller.com/pgws',
  // backendUrl: 'http://localhost:8080/',
  // backendWs: 'ws://localhost:8080/pgws',
  clientId: '755261507232-m3n1djtm2vto4qovaff42hck4q4dlak4.apps.googleusercontent.com',
  defaultAvatar: 'https://rs.kendrickheller.jp/images/fcm.png',
};

export default config;
